import React from 'react';
import { withRouter } from 'react-router';

import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';

import Allowance from '../allowance/Allowance';

import { connect } from 'react-redux';
import { getAccount } from '../redux/selectors';

class LeftNav extends React.Component {
  render() {
    return (
      <div id="leftNav">
      {this.props.location.pathname.indexOf('onboarding') === -1 && this.props.account.id ? (
        <div className="outer">
          <div className="inner">
            <Nav defaultActiveKey="/home" className="flex-column">
            <LinkContainer to="/app/chores">
              <Nav.Link href="/app/chores">Chores</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/app/periods">
              <Nav.Link>Past Weeks</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/app/family">
              <Nav.Link href="/app/family">Family</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/app/settings">
              <Nav.Link href="/app/settings">Settings</Nav.Link>
            </LinkContainer>
          </Nav>

          <Allowance />
          </div>
        </div>
      ) : null}
      </div>
    )

  }
}

const mapStateToProps = state => {
  return {
    account: getAccount(state)
  };
}

export default withRouter(connect(mapStateToProps)(LeftNav));