import React from 'react';

import { withRouter } from 'react-router-dom';

import GAService from './GAService';

class GAPageView extends React.Component {
  componentDidUpdate(oldProps) {
    if(this.props.location.pathname !== oldProps.location.pathname) {
      GAService.config({page_path: this.props.location.pathname});
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GAPageView);