import Util from './Util';

class Chore {
  constructor(options = {}) {
    this.id = options.id === undefined ? Util.newGuid() : options.id;
    this.description = options.description || '';
    this.hours = options.hours || 0;
    this.assignee = options.assignee;
  }
  
  clone() {
    return new Chore(
      {
        description: this.description,
        hours: this.hours
      }
    );
  }
}

export default Chore;