import React from 'react';

import { LinkContainer } from 'react-router-bootstrap';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Chore from '../shared/Chore';
import Util from '../shared/Util';

import {IoMdTrash} from 'react-icons/io';

import { connect } from 'react-redux';
import { getCurrentPeriod } from '../redux/selectors';
import { createChore, updateChore, deleteChore, completePeriod } from '../redux/actions';

class Chores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chores: props.period.chores.concat(new Chore())
    };

    this.markWeekComplete = this.markWeekComplete.bind(this);
    this.handleChoreCreate = this.handleChoreCreate.bind(this);
    this.handleChoreUpdate = this.handleChoreUpdate.bind(this);
    this.handleChoreDelete = this.handleChoreDelete.bind(this);
  }

  componentDidUpdate(oldProps) {
    if(this.props.period.id !== oldProps.period.id) {
      this.setState({
        chores: this.props.period.chores.concat(new Chore())
      });
    }
  }

  markWeekComplete() {
    let oldPeriod = this.props.period;
    let newPeriod = oldPeriod.clone();
  
    newPeriod.end.setDate(newPeriod.end.getDate() + 7);
    oldPeriod.complete = true;

    this.props.completePeriod(oldPeriod, newPeriod);
  }

  handleChoreCreate(chore) {
    this.props.createChore(chore);
    this.setState(state => {
      return {
        chores: state.chores.concat(new Chore())
      }
    });
  }

  handleChoreUpdate(chore) {
    this.props.updateChore(chore);
  }

  handleChoreDelete(chore) {
    this.setState(state => {
      this.props.deleteChore(chore);

      return {
        chores: state.chores.filter(oldChore => oldChore.id !== chore.id)
      }
    });
  }

  static getDefaultChores() {
    return [
      new Chore({description: 'Clear and wipe down table', hours: 1.75}),
      new Chore({description: 'Wash dishes', hours: 3.5}),
      new Chore({description: 'Sweep floor', hours: 1.75}),
      new Chore({description: 'Mow lawn', hours: 2}),
      new Chore({description: 'Clean downstairs bathroom', hours: 0.5}),
      new Chore({description: 'Clean upstairs bathroom', hours: 0.5}),
      new Chore({description: 'Clean basement bathroom', hours: 0.5}),
      new Chore({description: 'Take out garbage', hours: 0.5}),
      new Chore({description: 'Vacuum downstairs', hours: 0.5}),
      new Chore({description: 'Vacuum upstairs', hours: 0.5}),
      new Chore({description: 'Vacuum basement', hours: 0.5})
    ];
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
          <div className="float-right">
              <LinkContainer to="/app/chores/print">
                <Button className="mr-2" variant="info">
                  Print
                </Button>
              </LinkContainer>
              <Button disabled={false && new Date().getTime() < this.props.period.end.getTime()}
                onClick={this.markWeekComplete}>
                Mark Week Complete
              </Button>
            </div>
            <h1 className="title">Chores Week Ending {Util.formatDate(this.props.period.end)}</h1>
          </Col>
        </Row>
        
        <Form className="choresForm">
          <Row>
            <Col sm={5}><h3>Description</h3></Col>
            <Col sm={2}><h3>Hours per week</h3></Col>
            <Col sm={2}><h3>Price</h3></Col>
            <Col sm={2}><h3>Assignee</h3></Col>
            <Col sm={1}></Col>
          </Row>

          {this.state.chores.map((chore, index) => {
            return (
              <ChoreRow 
                key={chore.id}
                chore={chore}
                people={this.props.period.people}
                rate={this.props.period.rate}
                unpublished={index === this.state.chores.length - 1}
                onCreate={this.handleChoreCreate}
                onUpdate={this.handleChoreUpdate}
                onDelete={this.handleChoreDelete}
              />
            )
          })}

          <Row>
            <Col sm={5}></Col>
            <Col sm={2}>
              <strong>{
                this.state.chores.reduce((totalHours, chore) => {
                  return totalHours + chore.hours;
                }, 0)
                }</strong>
            </Col>
            <Col sm={2}>
              <strong>{
              Util.displayCurrency(
                this.state.chores.reduce((totalPrice, chore) => {
                  return totalPrice + chore.hours * this.props.period.rate;
                }, 0)
              )
              }</strong>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

class ChoreRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.chore);
    this.state.assignee = this.state.assignee || '';
    this.state.unpublished = props.unpublished;
    this.state.pristine = true;

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getChoreFromState() {
    return new Chore({
      id: this.state.id,
      description: this.state.description,
      hours: this.state.hours ? parseFloat(this.state.hours) : 0,
      assignee: this.state.assignee
    });
  }

  handleChange(e) {
    let target = e.target;
    this.setState(state => {
      return {
        ...state,
        [target.name]: target.value,
        pristine: false
      };
    });
  }

  handleSelectChange(e) {
    let target = e.target;
    this.setState(state => {
      let chore = this.getChoreFromState(state);
      
      chore[target.name] = target.value;

      if(state.unpublished) {
        this.props.onCreate(chore);
      } else {
        this.props.onUpdate(chore);
      }

      return {
        ...state,
        [target.name]: target.value,
        unpublished: false,
        pristine: true
      };
    });
  }

  handleBlur() {
    if(!this.state.pristine) {
      this.setState(state => {
        let chore = this.getChoreFromState();

        if(state.unpublished) {
          this.props.onCreate(chore);
        } else {
          this.props.onUpdate(chore);
        }

        return {
          ...state,
          unpublished: false,
          pristine: true
        };
      });
    }
  }

  handleDelete() {
    this.props.onDelete(this.getChoreFromState())
  }

  render() {
    return (
      <Row>
        <Col sm={5}>
          <FormControl
            value={this.state.description}
            name="description"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
        </Col>
        <Col sm={2}>
          <FormControl
              value={this.state.hours}
              name="hours"
              type="number"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
          />
        </Col>
        <Col sm={2}>
        {Util.displayCurrency(this.state.hours * this.props.rate)}
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Control 
              as="select"
              name="assignee"
              value={this.state.assignee}
              onChange={this.handleSelectChange}
            >
              <option key="0" value="">None</option>
              {this.props.people.map(person => (
                <option key={person.id} value={person.id}>{person.firstName}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={1}>
          <Button tabIndex="-1" variant="link" style={{color: "#000", padding: 0}} onClick={this.handleDelete}>
            <IoMdTrash />
          </Button>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  let period = getCurrentPeriod(state);
  return {
    inFlight: state.api.inFlight,
    error: state.api.error,
    period: period
  };
};

export default connect(mapStateToProps, { completePeriod, createChore, updateChore, deleteChore })(Chores);