import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Util from '../shared/Util';

import { connect } from 'react-redux';
import { getCurrentPeriod } from '../redux/selectors';

class Allowance extends React.Component {
  render() {
    return (
      <div className="allowance">
        {
          this.props.period.people.filter(person => person.type==='child').map(person => (
            <AllowanceRow key={person.id} person={person} chores={this.props.period.chores} rate={this.props.period.rate} />
          ))
        }
      </div>
    )
  }
}

class AllowanceRow extends React.Component {
  calculateAllowance(person) {
    let totalAmount = this.props.chores.reduce((amount, chore) => {
      return amount + chore.hours * this.props.rate;
    }, 0);

    let requiredAmount = person.requiredPercentage * totalAmount;

    let assignedAmount = this.props.chores.filter(chore => chore.assignee === person.id)
      .reduce((amount, chore) => {
        return amount + chore.hours * this.props.rate;
      }, 0);
    
    return assignedAmount - requiredAmount;
  }

  render() {
    this.allowance = this.calculateAllowance(this.props.person);
    return (
      <Row>
        <Col>{this.props.person.firstName}</Col>
        <Col className={`${this.allowance < 0 ? "negative" : ""}`}>
          
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip id={`tooltip-right`}>
                {this.allowance >= 0 ? (
                  <span>{this.props.person.firstName} will get {Util.displayCurrency(this.allowance)} at the end of the week.</span>
                ) : (
                  <span>{this.props.person.firstName} needs to pay {Util.displayCurrency(-this.allowance)} to get out of chores.</span>
                )}
              </Tooltip>
            }
          >
            <span>{Util.displayCurrency(this.allowance)}</span>
          </OverlayTrigger>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    period: props.period || getCurrentPeriod(state)
  };
}

export default connect(mapStateToProps)(Allowance);