import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MdClose } from 'react-icons/md';

import Person from '../shared/Person';

import { connect } from 'react-redux';
import { getCurrentPeriod } from '../redux/selectors';
import { createPerson, updatePerson, deletePerson } from '../redux/actions';

class FamilySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      people: props.period.people
    };

    this.state.people.push(
      new Person(
        {
          firstName: '',
          type: this.state.people.length > 1 ? 'child' : 'parent'
        }
      )
    );

    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleCreate(person) {
    this.props.createPerson(person);
    this.setState(state => {
      return {
        people: state.people.concat(new Person())
      }
    });
  }

  handleUpdate(person) {
    this.props.updatePerson(person);
  }

  handleDelete(person) {
    this.setState(state => {
      this.props.deletePerson(person);

      return {
        people: state.people.filter(oldPerson => oldPerson.id !== person.id)
      }
    });
  }

  render() {
    return (
      <div>
        <h1 className="title">Who's in your family?</h1>
    
        <Form>
        { this.state.people.map((person, index) => (
          <FamilyRow 
            key={person.id}
            person={person}
            last={index === this.state.people.length - 1}
            onCreate={this.handleCreate}
            onUpdate={this.handleUpdate}
            onDelete={this.handleDelete}
          />
        ))}
          { this.props.error ? (
            <p className="error">{this.props.error.message}</p>
          ) : null}
        </Form>
      </div>
    )
  }
}

class FamilyRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      person: props.person,
      unpublished: props.last,
      pristine: true
    };

    console.log(this.state);

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    this.setState(state => {
      return {
        ...state,
        pristine: false,
        person: new Person({
          ...state.person,
          [target.name]: target.value
        })
      }
    });
  }

  handleBlur(e) {
    if(this.state.pristine) {
      return;
    }

    if(this.state.unpublished) {
      this.setState(state => {
        return {
          ...state,
          unpublished: false
        };
      }, () => {
        this.props.onCreate(this.state.person);
      });
    } else {
      this.props.onUpdate(this.state.person);
    }
  }

  handleDelete(e) {
    this.props.onDelete(this.state.person);
  }

  render() {
    return (
      <Row>
        <Col>
          <Form.Control 
            placeholder="Name"
            name="firstName"
            value={this.state.person.firstName}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Control 
              as="select" 
              name="type"
              value={this.state.person.type}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            >
              <option value="child">Child</option>
              <option value="parent">Parent</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          {!this.props.last ? (
          <Button tabIndex="-1" variant="link" style={{color: "#000"}} onClick={this.handleDelete}>
            <MdClose />
          </Button>
          ): null}
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    inFlight: state.api.inFlight,
    error: state.api.error,
    period: getCurrentPeriod(state)
  };
}

export default withRouter(connect(mapStateToProps, { createPerson, updatePerson, deletePerson })(FamilySettings));