import React from 'react';

import { withRouter } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';

import { connect } from 'react-redux';
import { login } from '../redux/actions';
import { getAccount } from '../redux/selectors';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    this.setState(state => {
      return {
        ...state,
        [target.name]: target.value
      };
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.login(this.state.email, this.state.password);
  }

  render() {
    return (
      <>
      <h1 className="title">Login</h1>
      <Form onSubmit={this.handleSubmit}>
        <FormGroup controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            value={this.state.email}
            type="email"
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            value={this.state.password}
            type="password"
            onChange={this.handleChange}
          />
        </FormGroup>
      <Button type="submit">Submit</Button>
    </Form>
    {this.props.apiError ? (
      <p className="error">{this.props.apiError.message}</p>
    ): null}
    </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state)
  };
}

export default withRouter(connect(mapStateToProps, { login })(Login));