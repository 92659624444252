import {
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  LOGOUT
} from '../actionTypes';

const initialState = {
  id: null,
  email: null,
  agreeToTerms: null,
  firstName: null,
  lastName: null
};

export default function(state = initialState, action) {
  switch(action.type) {
    case CREATE_ACCOUNT: {
      return action.payload;
    }
    case UPDATE_ACCOUNT: {
      return action.payload;
    }
    case LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}