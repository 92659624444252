import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import OnboardingFamily from './OnboardingFamily';
import OnboardingRate from './OnboardingRate';

import { connect } from 'react-redux';
import { createAccount } from '../redux/actions';
import { getAccount } from '../redux/selectors';

import Account from '../shared/Account';

class Onboarding extends React.Component {
  componentDidMount() {
    if(!this.props.account.id) {
      //let account = new Account({onboarding: true});
      this.props.createAccount(new Account());
    }
  }

  render() {
    return this.props.account.id ? (
      <Switch>
        <Route path={`${this.props.match.path}/family`}>
          <OnboardingFamily />
        </Route>
        <Route path={`${this.props.match.path}/rate`}>
          <OnboardingRate />
        </Route>
        <Route path={`${this.props.match.path}/`}>
          <Redirect to={`${this.props.match.path}/family`} />
        </Route>
      </Switch>
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    account: getAccount(state)
  };
}

export default withRouter(connect(mapStateToProps, { createAccount })(Onboarding));