export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';

export const CREATE_PERIOD = 'CREATE_PERIOD';
export const UPDATE_PERIOD = 'UPDATE_PERIOD';
export const COMPLETE_PERIOD = 'COMPLETE_PERIOD';
export const CREATE_CHORE = 'CREATE_CHORE';
export const UPDATE_CHORE = 'UPDATE_CHORE';
export const DELETE_CHORE = 'DELETE_CHORE';
export const CREATE_PERSON = 'CREATE_PERSON';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const DELETE_PERSON = 'DELETE_PERSON';

export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';