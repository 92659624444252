class Util {
  static newGuid() {
    let arr = new Uint8Array(16);
    window.crypto.getRandomValues(arr);

    return Array.prototype.map.call(arr, val => {
      return ('00' + val.toString(16)).slice(-2);
    }).join('');
  }

  static displayCurrency(input) {
    return '$' + (Math.round(input * 100) / 100).toFixed(2);
  }

  static formatDate(date) {
    return date.toLocaleString().split(',')[0];
  }

  static getNextSunday() {
    let date = new Date();
    date.setDate(date.getDate() + (7 - date.getDay()));
    date.setHours(0, 0, 0, 0);
    return date;
  }
}

export default Util;