import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Allowance from '../allowance/Allowance';

import { connect } from 'react-redux';
import { getCurrentPeriod } from '../redux/selectors';

import Util from '../shared/Util';

class ChoresPrint extends React.Component {
  componentDidMount() {
    window.print();
  }

  render() {
    let peopleById = this.props.period.people.reduce((acc, person) => {
      acc[person.id] = person.firstName;
      return acc;
    }, {});

    let choresByPerson = this.props.period.chores.reduce((acc, chore) => {
      if(!acc[chore.assignee]) {
        acc[chore.assignee] = [];
      }

      acc[chore.assignee].push(chore);
      return acc;
    }, {});

    return (
      <div id="choresprint">
        <h1 className="pt-3 pb-3">Chore Economy Week Ending {Util.formatDate(this.props.period.end)}</h1>
        {Object.keys(choresByPerson)
          .sort((a, b) => {
            let aKey = peopleById[a] || 'zzz';
            let bKey = peopleById[b] || 'zzz';

            return aKey.localeCompare(bKey);
          })
          .map(personId => {
            return choresByPerson[personId].map(chore => (
              <ChoreRow chore={chore} rate={this.props.period.rate} person={peopleById[personId]} />
            ))
          })}

        <h2 className="mt-4">Allowance</h2>
        <Row>
          <Col sm={3}>
            <Allowance />
          </Col>
        </Row>
      </div>
    )
  }
}

class ChoreRow extends React.Component {
  render() {
    return (
      <Row>
        <Col sm={3}>{this.props.person || 'Unassigned'}</Col>
        <Col sm={6}>{this.props.chore.description}</Col>
        <Col sm={3}>{Util.displayCurrency(this.props.chore.hours * this.props.rate)}</Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    period: getCurrentPeriod(state)
  };
}

export default connect(mapStateToProps)(ChoresPrint);