import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getAccount, getCurrentPeriod } from '../redux/selectors';

class Redirector extends React.Component {
  isOnboarding() {
    return this.props.location.pathname.startsWith('/app/onboarding');
  }

  render() {
    let url = null;

    if(!this.props.account.id && !this.isOnboarding()) {
      url = '/app/login';
    } else if(!this.props.period.people.length) {
      url = '/app/onboarding/family';
    } else if(!this.props.period.rate) {
      url = '/app/onboarding/rate';
    } else if(this.props.account.id &&
      (
        this.props.location.pathname === '/' || 
        this.props.location.pathname === '/app' ||
        this.props.location.pathname === '/app/' ||
        this.props.location.pathname === '/app/login' ||
        this.props.location.pathname === '/app/onboarding/rate'
    )) {
      url = '/app/chores';
    }

    return url ? (
      <Redirect to={url} />
    ): null;
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
    period: getCurrentPeriod(state)
  };
}

export default withRouter(connect(mapStateToProps)(Redirector));