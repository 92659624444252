import Util from '../shared/Util';

class Period {
  constructor(options = {}) {
    this.id = this.id = options.id || Util.newGuid();
    this.end = options.end ? new Date(options.end) : new Date();
    this.complete = options.complete || false;
    this.chores = options.chores || [];
    this.people = options.people || [];
    this.rate = options.rate || 0;
  }

  clone() {
    return new Period(
      {
        end: this.end,
        complete: this.complete,
        chores: this.chores.map(chore => {
          return chore.clone();
        }),
        people: this.people.map(person => {
          return person.clone();
        }),
        rate: this.rate
      }
    );
  }
}

export default Period;