import {
  CREATE_PERSON,
  UPDATE_PERSON,
  CREATE_PERIOD,
  LOGOUT
} from '../actionTypes';

const initialState = {
  allIds: [],
  byIds: {}
};

export default function(state = initialState, action) {
  switch(action.type) {
    case CREATE_PERSON: {
      return {
        byIds: {
          ...state.byIds,
          [action.payload.id]: action.payload
        }
      }
    }
    case UPDATE_PERSON: {
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [action.payload.id]: action.payload
        }
      };
    }
    case CREATE_PERIOD: {
      const newPeopleIds = action.payload.people.map(person => person.id);
      const newPeopleObj = action.payload.people.reduce((obj, person) => {
        obj[person.id] = person;
        return obj;
      }, {});
      return {
        allIds: state.allIds.concat(newPeopleIds),
        byIds: Object.assign({}, state.byIds, newPeopleObj)
      };
    }
    case LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}