import Account from '../shared/Account';
import Period from '../shared/Period';
import Person from '../shared/Person';
import Chore from '../shared/Chore';

export const getPeriodById = (store, id) => {
  let period = new Period(store.periods.byIds[id]);
  period.chores = period.chores.map(id => new Chore(store.chores.byIds[id]));
  period.people = period.people.map(person => {
    return new Person(Object.assign({}, store.people.byIds[person.id], person));
  });
  return period;
}

export const getPeriods = store => {
  return store.periods.allIds.map(id => {
    return getPeriodById(store, id);
  });
}

export const getCurrentPeriod = store => {
  return getPeriodById(store, store.periods.currentId);
}

export const getAccount = store => {
  let account = new Account(store.account);
  if(!store.account.id) {
    account.id = null;
  }

  return account;
}

export const getSession = store => {
  return store.session;
}