import {
  CREATE_CHORE,
  UPDATE_CHORE,
  DELETE_CHORE,
  CREATE_PERIOD,
  LOGOUT
} from '../actionTypes';

const initialState = {
  byIds: {}
};

export default function(state = initialState, action) {
  switch(action.type) {
    case CREATE_CHORE: {
      return {
        byIds: {
          ...state.byIds,
          [action.payload.id]: action.payload
        }
      }
    }
    case UPDATE_CHORE: {
      return {
        byIds: {
          ...state.byIds,
          [action.payload.id]: action.payload
        }
      }
    }
    case DELETE_CHORE: {
      const {[action.payload.id]:_, ...newByIds} = state.byIds;
      return {
        byIds: newByIds
      }
    }
    case CREATE_PERIOD: {
      let newChores = action.payload.chores.reduce((obj, chore) => {
        obj[chore.id] = chore;
        return obj;
      }, {});

      return {
        byIds: Object.assign({}, state.byIds, newChores)
      }
    }
    case LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}