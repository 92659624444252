import Period from '../shared/Period';
import Person from '../shared/Person';
import Chore from '../shared/Chore';
import axios from 'axios';

class Api {
  constructor() {
    this.prefix = process.env.REACT_APP_API_PREFIX || 'https://choreeconomy.com/api';
    this.currentPeriod = null;
    this.options = {};
  }

  removeAccount() {
    this.options = {};
  }

  setAccount(id) {
    this.options = {
      auth: {
        username: id,
        password: ''
      }
    };
  }

  getAccountByEmailAndPassword(email, password) {
    let options = {
      auth: {
        username: email,
        password: password
      }
    };

    return axios.get(this.prefix + '/account', options)
      .then(res => {
        if(res.data) {
          this.setAccount(res.data.id);
          return res.data;
        }
      });
  }

  getAccount(id) {
    this.setAccount(id);
    return axios.get(this.prefix + '/account', this.options)
      .then(res => {
        return res.data;
      })
  }

  createAccount(account) {
    return axios.post(this.prefix + '/account', account, this.options);
  }

  updateAccount(account) {
    return axios.put(this.prefix + '/account', account, this.options);
  }

  getPeriods() {
    return axios.get(this.prefix + '/periods', this.options)
      .then(res => {
        return res.data.map(period => {
          period = new Period(period);
          period.people = period.people.map(person => new Person(person));
          period.chores = period.chores.map(chore => new Chore(chore));

          if(!period.complete) {
            this.currentPeriod = period;
          }
          return period;
        });
      })
  }

  createPeriod(period) {
    this.currentPeriod = period;
    return axios.post(this.prefix + '/periods', period, this.options);
  }

  updatePeriod(period) {
    this.currentPeriod = period;
    return axios.put(this.prefix + '/periods/' + period.id, period, this.options);
  }

  createChore(chore) {
    if(!this.currentPeriod) {
      return Promise.reject(new Error('Missing current period'));
    }

    return axios.post(this.prefix + '/periods/' + this.currentPeriod.id + '/chores', chore, this.options);
  }

  updateChore(chore) {
    return axios.put(this.prefix + '/chores/' + chore.id, chore, this.options);
  }

  deleteChore(chore) {
    if(!this.currentPeriod) {
      return Promise.reject(new Error('Missing current period'));
    }

    return axios.delete(this.prefix + '/periods/' + this.currentPeriod.id + '/chores/' + chore.id, this.options);
  }

  createPerson(person) {
    if(!this.currentPeriod) {
      return Promise.reject(new Error('Missing current period'));
    }

    return axios.post(this.prefix + '/periods/' + this.currentPeriod.id + '/people', person, this.options);
  }

  updatePerson(person) {
    if(!this.currentPeriod) {
      return Promise.reject(new Error('Missing current period'));
    }

    return axios.put(this.prefix + '/periods/' + this.currentPeriod.id + '/people/' + person.id, person, this.options);
  }

  deletePerson(person) {
    if(!this.currentPeriod) {
      return Promise.reject(new Error('Missing current period'));
    }

    return axios.delete(this.prefix + '/periods/' + this.currentPeriod.id + '/people/' + person.id, this.options);
  }
  
}

export default Api;