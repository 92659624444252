let id = 'UA-158311207-1';

class GAService {
  static config(options) {
    window.gtag('config', id, options);
  }

  static event(name, options) {
    window.gtag('event', name, options);
  }
}

export default GAService;