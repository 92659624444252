import {
  API_REQUEST,
  API_SUCCESS,
  API_FAILURE
} from '../actionTypes';

const initialState = {
  inFlight: false,
  error: null
};

export default function(state = initialState, action) {
  switch(action.type) {
    case API_REQUEST: {
      return {
        inFlight: true,
        error: null
      };
    }
    case API_SUCCESS: {
      return {
        inFlight: false,
        error: null
      }
    }
    case API_FAILURE: {
      return {
        inFlight: false,
        error: action.payload
      }
    }
    default:
      return state;
  }
}