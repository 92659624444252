import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';

import Period from '../shared/Period';

import { connect } from 'react-redux';
import { getCurrentPeriod } from '../redux/selectors';
import { updatePeriod } from '../redux/actions';

import GAService from '../ga/GAService';

class OnboardingRate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.back = this.back.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    this.setState(() => {
      return {
        rate: target.value
      };
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.updatePeriod(new Period(
      {
        ...this.props.period,
        rate: this.state.rate ? parseFloat(this.state.rate) : 0
      }
    ));
    GAService.event('set_rate', {event_category: 'onboarding'});
  }

  back() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div>
        <h1 className="title">Hourly Rate</h1>

        <p>
          How much are you willing to pay per hour of chores?
        </p>
    
        <Form onSubmit={this.handleSubmit}>
          <FormGroup controlId="allowance">
            <Form.Label>Hourly Rate</Form.Label>
            <Form.Control
              type="number"
              value={this.state.rate}
              onChange={this.handleChange}
            />
          </FormGroup>

          <p>You can change this on the Settings page at any time. You can also set a required 
            amount of chores your kids must do before they get paid an allowance.
          </p>
          {this.props.error ? (
            <p className="error">{this.props.error.message}</p>
          ): ''}
          <div>
            <Button type="submit">Next</Button>
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    period: getCurrentPeriod(state),
    inFlight: state.api.inFlight,
    error: state.api.error
  };
}

export default withRouter(connect(mapStateToProps, { updatePeriod })(OnboardingRate));