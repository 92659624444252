import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAccount } from '../redux/selectors';
import { updateAccount } from '../redux/actions';

import Account from '../shared/Account';

import GAService from '../ga/GAService';

class AlertBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  setAlert() {
    let alert = null;

    if(!this.props.account.emailVerified) {
      alert = 'verifyEmail';
    }

    if(!this.props.account.email) {
      alert = 'needAccount';
    }

    if(this.props.location.pathname.startsWith('/app/onboarding') ||
      this.props.location.pathname === '/app/login' ||
      this.props.location.pathname === '/') {
      alert = null;
    }

    this.setState(state => {
      return {
        ...state,
        alert: alert
      };
    });
  }

  componentDidMount() {
    this.setAlert();
  }

  componentDidUpdate(oldProps) {
    if(this.state.showModal && oldProps.inFlight && !this.props.inFlight && !this.props.apiError) {
      this.setState({
        showModal: false,
        alert: 'accountSuccess'
      });
      setTimeout(() => {
        this.setState(state => {
          return {
            ...state,
            alert: state.alert === 'accountSuccess' ? null : state.alert
          };
        });
      }, 5000);

      return;
    }

    if(oldProps.location.pathname !== this.props.location.pathname) {
      return this.setAlert();
    }

    if(oldProps.account.id && !this.props.account.id) {
      return this.setAlert();
    }
  }

  handleCloseModal() {
    this.setState({showModal: false});
  }

  showModal(e) {
    e.preventDefault();
    this.setState({showModal: true});
  }

  handleSave(accountForm) {
    let account = new Account(Object.assign({}, this.props.account, accountForm));
    this.props.updateAccount(account);
    GAService.event('sign_up', {method: 'site'});
  }

  renderNeedAccount() {
    return (
      <>
      <Alert variant="warning">
        Your information is not saved. Please <Alert.Link onClick={this.showModal}>create an account</Alert.Link> to save your information.
      </Alert>
      <CreateAccountModal 
        apiError={this.props.apiError}
        showModal={this.state.showModal}
        onClose={this.handleCloseModal}
        onSave={this.handleSave}
      />
      </>
    )
  }

  renderVerifyEmail() {
    return (
      <Alert variant="warning">
        An email was sent to {this.props.account.email}. Please click the link to verify your email address.
      </Alert>
    )
  }

  renderAccountSuccess() {
    return (
      <Alert variant="success">
        Account successfully created.
      </Alert>
    )
  }

  render() {
    switch(this.state.alert) {
      case 'needAccount': {
        return this.renderNeedAccount();
      }
      case 'accountSuccess': {
        return this.renderAccountSuccess();
      }
      case 'verifyEmail': {
        return this.renderVerifyEmail();
      }
      default:
        return null;
    }
  }
}

class CreateAccountModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
      agreeToTerms: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  handleClose() {
    this.props.onClose();
  }

  handleSave() {
    this.props.onSave(this.state);
  }

  handleChange(e) {
    let target = e.target;
    this.setState(state => {
      return {
        ...state,
        [target.name]: target.type === 'checkbox' ? target.checked : target.value
      };
    });
  }

  isValid() {
    let valid = this.state.firstName &&
      this.state.lastName &&
      this.validEmail(this.state.email) &&
      this.state.password &&
      this.state.password === this.state.password2 &&
      this.state.agreeToTerms === true;
    return valid;
  }

  validEmail(email) {
    // eslint-disable-next-line
    return (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email));
  }

  render() {
    return (
      <>
      <Modal id="createAccountModal" show={this.props.showModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Control
              name="firstName"
              value={this.state.firstName}
              placeholder="First Name"
              onChange={this.handleChange}
            />
            <Form.Control
              name="lastName"
              value={this.state.lastName}
              placeholder="Last Name"
              onChange={this.handleChange}
            />
            <Form.Control
              name="email"
              value={this.state.email}
              type="email"
              placeholder="Email address"
              onChange={this.handleChange}
              isInvalid={this.state.email && !this.validEmail(this.state.email)}
            />
            <Form.Control
              name="password"
              value={this.state.password}
              type="password"
              placeholder="Password"
              onChange={this.handleChange}
            />
            <Form.Control
              name="password2"
              value={this.state.password2}
              type="password"
              placeholder="Repeat password"
              onChange={this.handleChange}
              isInvalid={this.state.password2 !== this.state.password}
            />
            <Form.Group>
              <Form.Check
                type="checkbox"
              >
                <Form.Check.Input 
                  type="checkbox" 
                  name="agreeToTerms"
                  checked={this.state.agreeToTerms}
                  onChange={this.handleChange}
                />
                <Form.Check.Label>I agree to <a href='/terms' rel="noopener noreferrer" target="_blank">Terms of Use</a></Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Form>
          {this.props.apiError ? (
            <p className="error">{this.props.apiError.message}</p>
          ): null}
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="primary"
            disabled={!this.isValid()}
            onClick={this.handleSave}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: getAccount(state),
    apiError: state.api.error,
    inFlight: state.api.inFlight
  };
}

export default withRouter(connect(mapStateToProps, { updateAccount })(AlertBar));