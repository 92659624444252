import {
  LOGIN,
  LOGOUT,
  API_SUCCESS,
  API_FAILURE
} from '../actionTypes';

const initialState = {
  initializing: true,
  error: null
};

export default function(state = initialState, action) {
  switch(action.type) {
    case LOGIN: {
      return {
        initializing: true,
        error: null
      };
    }
    case API_FAILURE: {
      return state.initializing ?
        {
          initializing: false,
          error: action.payload
        } : state;
    }
    case API_SUCCESS: {
      return {
        initializing: false,
        error: null
      };
    }
    case LOGOUT: {
      return {
        initializing: false,
        error: null
      };
    }
    default:
      return state;
  }
}