import React from 'react';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { getCurrentPeriod } from '../redux/selectors';
import { updatePeriod } from '../redux/actions';
import { updatePerson } from '../redux/actions';


class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: props.period.rate,
      periodEnd: props.period.end.getDay(),
      people: props.period.people
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRequiredPercentageUpdate = this.handleRequiredPercentageUpdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    this.setState(state => {
      return {
        ...state,
        [target.name]: target.value
      };
    });
  }

  handleRequiredPercentageUpdate(person) {
    let people = this.state.people.map(oldPerson => {
      return oldPerson.id === person.id ? person : oldPerson;
    });

    this.setState(state => {
      return {
        ...state,
        people: people
      };
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let periodEnd = new Date();
    let diff = this.state.periodEnd - periodEnd.getDay();
    periodEnd.setDate(periodEnd.getDate() + (diff > 0 ? diff : diff + 7));
    periodEnd.setHours(0, 0, 0, 0);

    this.props.updatePeriod({
      ...this.props.period,
      rate: this.state.rate ? parseFloat(this.state.rate) : 0,
      end: periodEnd,
      people: this.state.people
    });

    this.state.people.forEach(person => {
      this.props.updatePerson(person);
    });
  }


  render() {
    return (
      <div>
        <h1 className="title">Settings</h1>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup controlId="rate">
            <FormLabel>Hourly Rate</FormLabel>
            <FormControl 
              name="rate"
              type="number"
              value={this.state.rate}
              onChange={this.handleChange}
            />
          </FormGroup>

          <FormGroup controlId="periodEnd">
            <FormLabel>Week Ends On</FormLabel>
            <Form.Control
              as="select"
              name="periodEnd"
              value={this.state.periodEnd}
              onChange={this.handleChange}
            >
              <option value="0">Sunday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
            </Form.Control>
          </FormGroup>

          <h2>Chores Required</h2>

          <p>
            You can require a certain percentage of chores that your kids need to do before an allowance will be paid out.
            If they do fewer chores, they need to pay to get out of them.
          </p>

          {
            this.state.people.filter(person => person.type === 'child')
              .map(person => {
                return (
                  <SettingsRequiredPercentage 
                    key={person.id}
                    person={person}
                    onUpdate={this.handleRequiredPercentageUpdate}
                  />
                )
              })
          }


          {this.props.error ? (
            <p className="error">{this.props.error}</p>
          ): null}
          <Button type="submit">Save</Button>

        </Form>
      </div>
     
    )
  }
}

class SettingsRequiredPercentage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requiredPercentage: props.person.requiredPercentage * 100
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e) {
    this.setState({requiredPercentage: e.target.value});
  }

  handleBlur() {
    let person = Object.assign({}, this.props.person);
    person.requiredPercentage = this.state.requiredPercentage ? parseFloat(this.state.requiredPercentage) / 100 : 0;
    this.props.onUpdate(person);
  }

  render() {
    return (
      <Row key={this.props.person.id}>
        <Col>
          {this.props.person.firstName}
        </Col>
        <Col>
          <Form.Group controlId={'requiredPercentage'+this.props.person.id}>
            <InputGroup>
              <Form.Control
                name="requiredPercentage"
                type="number"
                value={this.state.requiredPercentage}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <InputGroup.Append>
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>

        </Col>
      </Row>
    );
  }


}

const mapStateToProps = state => {
  return {
    period: getCurrentPeriod(state),
    error: state.api.error
  };
}
export default withRouter(connect(mapStateToProps, { updatePeriod, updatePerson })(Settings));