import Util from './Util';

class Person {
  constructor(options = {}) {
    this.id = options.id || Util.newGuid();
    this.firstName = options.firstName || '';
    this.type = options.type || 'child';
    this.allowance = options.allowance || 10;
    this.requiredPercentage = options.requiredPercentage || 0;
  }

  clone() {
    return new Person(
      {
        id: this.id,
        firstName: this.firstName,
        type: this.type,
        allowance: this.allowance,
        requiredPercentage: this.requiredPercentage
      }
    );
  }
}

export default Person;