import { 
  CREATE_PERIOD,
  UPDATE_PERIOD,
  CREATE_CHORE,
  DELETE_CHORE,
  CREATE_PERSON,
  DELETE_PERSON,
  LOGOUT
} from '../actionTypes';

const initialState = {
  allIds: [],
  byIds: {},
  currentId: null
};

export default function(state = initialState, action) {
  switch(action.type) {
    case CREATE_PERIOD: {
      const period = Object.assign({}, action.payload);
      period.chores = period.chores.map(chore => chore.id);
      period.people = period.people.map(person => {
        return {
          id: person.id,
          requiredPercentage: person.requiredPercentage
        };
      });

      return {
        ...state,
        allIds: [...state.allIds, period.id],
        byIds: {
          ...state.byIds,
          [period.id]: period
        },
        currentId: !period.complete ? period.id : state.currentId
      };
    }
    case UPDATE_PERIOD: {
      const period = Object.assign({}, action.payload);
      period.chores = period.chores.map(chore => chore.id);
      period.people = period.people.map(person => {
        return {
          id: person.id,
          requiredPercentage: person.requiredPercentage
        };
      });

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [period.id]: period
        }
      }
    }
    case CREATE_CHORE: {
      let currentPeriod = state.byIds[state.currentId];
      let newPeriod = {
        ...currentPeriod,
        chores: currentPeriod.chores.concat(action.payload.id)
      };
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [currentPeriod.id]: newPeriod
        }
      };
    }
    case DELETE_CHORE: {
      let currentPeriod = state.byIds[state.currentId];
      let newPeriod = {
        ...currentPeriod,
        chores: currentPeriod.chores.filter(chore => chore.id !== action.payload.id)
      };
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [currentPeriod.id]: newPeriod
        }
      };
    }
    case CREATE_PERSON: {
      let currentPeriod = state.byIds[state.currentId];
      let newPerson = {
        id: action.payload.id,
        requiredPercentage: action.payload.requiredPercentage
      };
      let newPeriod = {
        ...currentPeriod,
        people: currentPeriod.people.concat(newPerson)
      };
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [currentPeriod.id]: newPeriod
        }
      };
    }
    case DELETE_PERSON: {
      let currentPeriod = state.byIds[state.currentId];
      let newPeriod = {
        ...currentPeriod,
        people: currentPeriod.people.filter(person => person.id !== action.payload.id)
      };
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [currentPeriod.id]: newPeriod
        }
      };
    }
    case LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}