import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';

import Layouts from './layouts/Layouts';

import Onboarding from './onboarding/Onboarding';
import Login from './account/Login';
import ChoresPrint from './chores/ChoresPrint';
import Chores from './chores/Chores';
import PeriodList from './periods/PeriodList';
import FamilySettings from './settings/Family';
import Settings from './settings/Settings';
import Redirector from './account/Redirector';

import { connect } from 'react-redux';
import { getSession } from './redux/selectors';
import { loginById, getPeriods, logout } from './redux/actions';

import GAPageView from './ga/GAPageView';

import './App.css';


const Home = () => (
  <Jumbotron>
    <h1 className="header">Chore Economy</h1>
    <p>Teach your kids about money, and get them to do chores!</p>
    <p>
      <Link to="/app/onboarding/family">
        <Button variant="primary">Get Started</Button>
      </Link>
    </p>

    <p>The fairest, most fun way to do allowance.</p>
  </Jumbotron>
)

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)

class App extends React.Component {
  componentDidMount() {
    let accountId = window.localStorage.getItem('accountId');
    if(accountId) {
      this.props.loginById(accountId);
    } else {
      this.props.logout();
    }
  }

  render() {
    return (
      <Router>
        {!this.props.session.initializing ? (
          <>
          <GAPageView />
          <Redirector />
          <Switch>
            <AppRoute path="/app/login" layout={Layouts.LoggedOut} component={Login} />
            <AppRoute path="/app/onboarding" layout={Layouts.LoggedOut} component={Onboarding} />
            <AppRoute path="/app/chores/print" layout={Layouts.Print} component={ChoresPrint} />
            <AppRoute path="/app/chores" layout={Layouts.Main} component={Chores} />
            <AppRoute path="/app/periods" layout={Layouts.Main} component={PeriodList} />
            <AppRoute path="/app/family" layout={Layouts.Main} component={FamilySettings} />
            <AppRoute path="/app/settings" layout={Layouts.Main} component={Settings} />
            <AppRoute path="/" layout={Layouts.LoggedOut} component={Home} />
          </Switch>
          </>
        ): null}
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    inFlight: state.api.inFlight,
    error: state.api.error,
    session: getSession(state),
    account: state.account
  }
}

export default connect(mapStateToProps, { loginById, getPeriods, logout })(App);