import { combineReducers } from 'redux';
import api from './api';
import session from './session';
import account from './account';
import periods from './periods';
import people from './people';
import chores from'./chores';

export default combineReducers(
  {
    api,
    session,
    account,
    periods,
    people,
    chores
  });