import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MdClose } from 'react-icons/md';

import Person from '../shared/Person';
import Period from '../shared/Period';
import Chores from '../chores/Chores';
import Util from '../shared/Util';

import { connect } from 'react-redux';
import { createPeriod } from '../redux/actions';

import GAService from '../ga/GAService';

class OnboardingFamily extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      people: []
    };

    this.state.people.push(
      new Person(
        {
          firstName: '',
          type: this.state.people.length > 1 ? 'child' : 'parent'
        }
      )
    );

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUpdate(person) {
    this.setState(state => {
      let newPeople = state.people.map(oldPerson => {
        return oldPerson.id === person.id ? person : oldPerson;
      });
      let lastPerson = state.people[state.people.length - 1];
      if(lastPerson.id === person.id) {
        newPeople.push(new Person({
          type: newPeople.length > 1 ? 'child' : 'parent'
        }));
      }

      return {
        people: newPeople
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    let filtered = this.state.people.filter(person => {
      return person.firstName;
    });

    let period = new Period({
      end: Util.getNextSunday(),
      people: filtered,
      chores: Chores.getDefaultChores()
    });

    this.props.createPeriod(period);
    GAService.event('create_family', {event_category: 'onboarding'});
  }

  handleDelete(person) {
    this.setState(state => {
      return {
        people: state.people.filter(oldPerson => oldPerson.id !== person.id)
      }
    });
  }

  render() {
    return (
      <div>
        <h1 className="title">Who's in your family?</h1>
    
        <Form onSubmit={this.handleSubmit}>
        { this.state.people.map((person, index) => (
          <FamilyRow 
            key={person.id}
            person={person}
            last={index === this.state.people.length - 1}
            onUpdate={this.handleUpdate}
            onDelete={this.handleDelete}
          />
        ))}
          { this.props.error ? (
            <p className="error">{this.props.error.message}</p>
          ) : null}
          <Button type="submit">Next</Button>
        </Form>
      </div>
    )
  }
}

class FamilyRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      person: props.person
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    this.setState(state => {
      return {
        person: new Person({
          ...state.person,
          [target.name]: target.value
        })
      }
    }, () => {
      this.props.onUpdate(this.state.person);
    });
  }

  handleDelete(e) {
    this.props.onDelete(this.state.person);
  }

  render() {
    return (
      <Row>
        <Col>
          <Form.Control 
            placeholder="Name"
            name="firstName"
            value={this.state.person.firstName}
            onChange={this.handleChange}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Control as="select" name="type" value={this.state.person.type} onChange={this.handleChange}>
              <option value="child">Child</option>
              <option value="parent">Parent</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          {!this.props.last ? (
          <Button tabIndex="-1" variant="link" style={{color: "#000"}} onClick={this.handleDelete}>
            <MdClose />
          </Button>
          ): null}
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    inFlight: state.api.inFlight,
    error: state.api.error
  };
}

export default withRouter(connect(mapStateToProps, { createPeriod })(OnboardingFamily));