import React from 'react';

import { withRouter } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LoginButton from '../account/LoginButton';
import LeftNav from '../nav/LeftNav';
import AlertBar from '../account/AlertBar';

import { connect } from 'react-redux';

class Main extends React.Component {
  render() {
    return (
      <div id="approot">
      <Navbar bg="dark" variant="dark" className="fixed-top">
      <Navbar.Brand href="/">
        Chore Economy
      </Navbar.Brand>

      <Nav className="mr-auto">
        <Nav.Link href="/faq">FAQ</Nav.Link>
        <Nav.Link href="/contact">Contact</Nav.Link>
      </Nav>
      <Nav>
        <LoginButton />
      </Nav>
    </Navbar>
    <Container className="p-3">
      <Row>
        <Col md={2}>
          <LeftNav />
        </Col>
        <Col>
          {this.props.error ? (
            <p className="error">{this.props.error.message}</p>
          ): null}
          {!this.props.loggedOut ? (<AlertBar />): null}
          {this.props.children}

        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="text-center">
          Copyright &copy; {(new Date()).getFullYear()} <a href="https://openaccounting.io" rel="noopener noreferrer" target="_blank">Open Accounting, LLC</a><br/>
          <a href="/terms">Terms of Use</a>
        </Col>
      </Row>
    </Container>
    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.api.error
  };
}

Main = withRouter(connect(mapStateToProps)(Main));

class LoggedOut extends React.Component {
  render() {
    return (
      <Main loggedOut={true} children={this.props.children} />
    )
  }
}

class Print extends React.Component {
  render() {
    return (
      <Container>
        {this.props.children}
      </Container>
    )
  }
}

Print = withRouter(Print);

export default {
  Main: Main,
  LoggedOut: LoggedOut,
  Print: Print
}