import {
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  CREATE_PERIOD,
  UPDATE_PERIOD,
  CREATE_CHORE,
  UPDATE_CHORE,
  DELETE_CHORE,
  CREATE_PERSON,
  UPDATE_PERSON,
  DELETE_PERSON,
  API_REQUEST,
  API_SUCCESS,
  API_FAILURE,
  LOGIN,
  LOGOUT
} from './actionTypes';

import Api from './api';

let api = new Api();

export const apiAction = (action, handler) => {
  return dispatch => {
    dispatch(apiRequest());
    dispatch(action);
    handler(action.payload)
      .then(() => {
        dispatch(apiSuccess());
      })
      .catch(err => {
        dispatch(apiFailure(new Error(err.response && err.response.data)));
      });
  }
}

export const login = (email, password) => {
  return dispatch => {
    dispatch({
      type: LOGIN
    });
    dispatch(apiRequest());
    api.getAccountByEmailAndPassword(email, password)
      .then(account => {
        window.localStorage.setItem('accountId', account.id);
        dispatch({
          type: CREATE_ACCOUNT,
          payload: account
        });
        dispatch(getPeriods());
      })
      .catch((err) => {
        console.log(err);
        dispatch(apiFailure(new Error('Invalid email or password')));
      })
  }
}

export const logout = () => {
  window.localStorage.removeItem('accountId');
  api.removeAccount();
  return {
    type: LOGOUT
  };
}

export const loginById = (accountId) => {
  return dispatch => {
    dispatch({
      type: LOGIN
    });
    dispatch(apiRequest());
    api.getAccount(accountId)
      .then((account) => {
        dispatch({
          type: CREATE_ACCOUNT,
          payload: account
        });
        dispatch(getPeriods());
      })
      .catch(err => {
        dispatch(apiFailure(new Error(err.response && err.response.data)));
        dispatch(logout());
      })
  }
}

export const createAccount = account => {
  let action = {
    type: CREATE_ACCOUNT,
    payload: account
  };

  return dispatch => {
    dispatch(apiRequest());
    api.createAccount(action.payload)
      .then(() => {
        window.localStorage.setItem('accountId', account.id);
        api.setAccount(account.id);
        dispatch(apiSuccess());
        dispatch(action);
      })
      .catch(err => {
        dispatch(apiFailure(new Error(err.response && err.response.data)));
      });
    };
}

export const updateAccount = account => {
  let action = {
    type: UPDATE_ACCOUNT,
    payload: account
  };

  return dispatch => {
    dispatch(apiRequest());
    api.updateAccount(action.payload)
      .then(() => {
        dispatch(apiSuccess());
        dispatch(action);
      })
      .catch(err => {
        dispatch(apiFailure(new Error(err.response && err.response.data)));
      })
  };
}

export const getPeriods = () => {
  return dispatch => {
    dispatch(apiRequest());
    api.getPeriods()
      .then(periods => {
        periods.forEach(period => {
          dispatch({
            type: CREATE_PERIOD,
            payload: period
          });
        });
        dispatch(apiSuccess());
      })
      .catch(err => {
        dispatch(apiFailure(new Error(err.response && err.response.data)));
      });
  }
}

export const createPeriod = period => {
  let action = {
    type: CREATE_PERIOD,
    payload: period
  };

  return apiAction(action, api.createPeriod.bind(api));
}

export const updatePeriod = period => {
  let action = {
    type: UPDATE_PERIOD,
    payload: period
  };

  return apiAction(action, api.updatePeriod.bind(api));
}

export const completePeriod = (oldPeriod, newPeriod) => {
  return dispatch => {
    dispatch(apiRequest());
    dispatch({
      type: 'UPDATE_PERIOD',
      payload: oldPeriod
    });
    dispatch({
      type: 'CREATE_PERIOD',
      payload: newPeriod
    });
    api.updatePeriod(oldPeriod)
      .then(() => {
        return api.createPeriod(newPeriod);
      })
      .then(() => {
        dispatch(apiSuccess());
      })
      .catch(err => {
        dispatch(apiFailure(err));
      });
  }
}

export const createChore = chore => {
  let action = {
    type: CREATE_CHORE,
    payload: chore
  };

  return apiAction(action, api.createChore.bind(api));
}

export const updateChore = chore => {
  let action = {
    type: UPDATE_CHORE,
    payload: chore
  };

  return apiAction(action, api.updateChore.bind(api));
}

export const deleteChore = chore => {
  let action = {
    type: DELETE_CHORE,
    payload: chore
  };

  return apiAction(action, api.deleteChore.bind(api));
}

export const createPerson = person => {
  let action = {
    type: CREATE_PERSON,
    payload: person
  };

  return apiAction(action, api.createPerson.bind(api));
}

export const updatePerson = person => {
  let action = {
    type: UPDATE_PERSON,
    payload: person
  };

  return apiAction(action, api.updatePerson.bind(api));
}

export const deletePerson = person => {
  let action = {
    type: DELETE_PERSON,
    payload: person
  };

  return apiAction(action, api.deletePerson.bind(api));
}


export const apiRequest = () => ({
  type: API_REQUEST
});

export const apiSuccess = () => ({
  type: API_SUCCESS
});

export const apiFailure = error => ({
  type: API_FAILURE,
  payload: error
});