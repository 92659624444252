import React from 'react';

import Util from '../shared/Util';
import Allowance from '../allowance/Allowance';

import { connect } from 'react-redux';
import { getPeriods } from '../redux/selectors';

class PeriodList extends React.Component {
  render() {
    return (
      <div id="periodList">
        <h1 className="title">
          Past Weeks
        </h1>
        <ul>
          {this.props.periods.map(period => (
            <PeriodListItem key={period.id} period={period}/>
          ))}
        </ul>
      </div>

    )
  }
}

class PeriodListItem extends React.Component {
  render() {
    return (
      <li>
        {Util.formatDate(this.props.period.end)}
        <Allowance period={this.props.period}/>
      </li>
    )
  }
}

const mapStateToProps = state => {
  return {
    periods: getPeriods(state).filter(period => period.complete)
  };
}

export default connect(mapStateToProps)(PeriodList);