import React from 'react';

import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';

import { connect } from 'react-redux';
import { getAccount } from '../redux/selectors';
import { logout } from '../redux/actions';

class LoginButton extends React.Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout(e) {
    e.preventDefault();
    this.props.logout();
  }

  render() {
    return this.props.account.id ? (
      <Nav.Link onClick={this.logout}>{this.props.account.email ? 'Logout' : 'Login'}</Nav.Link>
    ) : (
      <LinkContainer to="/app/login">
        <Nav.Link href="#login">Login</Nav.Link>
      </LinkContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state)
  };
}

export default connect(mapStateToProps, { logout })(LoginButton);