import Util from './Util';

class Account {
  constructor(options = {}) {
    this.id = options.id || Util.newGuid();
    this.firstName = options.firstName;
    this.lastName = options.lastName;
    this.email = options.email;
    this.emailVerified = options.emailVerified;
    this.password = options.password;
    this.agreeToTerms = options.agreeToTerms;
    this.onboarding = options.onboarding;
  }
}

export default Account;